<template>
  <div id="app" style="padding:15px; overflow:auto; width:calc(100% - 30px)">
    <loading :loading="loading" text="提交中"></loading>
    <el-form ref="form" :model="admin"  label-width="100px">
      <el-form-item label="用户名"  :required="true">
        <el-input type="text"  v-model="admin.username" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="重置密码"  :required="true">
        <el-input type="text"  v-model="admin.password" placeholder="请输入新密码"></el-input>
      </el-form-item>
      <el-form-item style="display: none">
        <el-button @click="submit()" id="layuiadmin-app-form-submit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
  TipEnum,
  UrlEnum,
  request, checkPassword,
} from "../../../public/js/common-vue";
import Loading from "@/components/Loading";

export default {
  name: "AdminReset",
  data() {
    return {
      loading: false,
      id:0,
      admin:{
        username:'',
        password:''
      }
    }
  },
  components: {Loading},
  mounted() {
      this.id = request('id');
      this.initData();
  },
  methods:{
    submit:function (){
      if(this.admin.password == null || this.admin.password == ''){
        this.showMsgError("请输入密码");
        return;
      }
      if (this.admin.password.length < 6) {
        this.showMsgError("请输入6位以上密码");
        return;
      }
      if (!checkPassword(this.admin.password)) {
        this.showMsgError("密码中必须包含字母、数字、特殊字符");
        return;
      }
      this.loading = true;
      let url = UrlEnum.ADMINS_PASSWORD;
      let method = "put";
      this.$http({
        method: method,
        url: url,
        data: this.admin,
      })
      .then((res) => {
        this.loading=false;
        this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
        setTimeout(function () {
          window.parent.postMessage({
            msg: '',
            key: 'refresh'
          }, '*')
        }, 800);
      })
      .catch((res) => {
        this.loading=false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    initData(){
      this.loading = true;
      this.$http({
        method: 'GET',
        url:UrlEnum.ADMINS + "/" + request('id'),
        data: '',
      })
      .then((res) => {
        this.loading = false;
        this.admin = res.data;
      })
      .catch((res) => {
        this.loading=false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
  }
}
</script>

<style scoped>
</style>
